.App {
  /* text-align: center; */
  margin: 0px;
  font-family: 'Times New Roman', Times, serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
/* h1{
  font-size: 64px;
line-height: 64px;
letter-spacing: 0.02em;
color: #000000;
} */
/* h5{
  font-size: 24px;
} */
/* h4{
  font-size: 32px;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.Loader{
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: fixed;
  z-index: 999999999;
}
.Loaderoverlay {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, .5);
  position: fixed;
}
.mainloader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

/* song slider css */
.carousel {
  position: relative;
  width: 50%;
  height: 50px; 
  margin: 0 auto;
}

.carousel-content {
  position: relative;
  overflow: hidden;
  transition: width .4s;
  height: 100%;
}

.slide {
  height: 100%;
  background-color: black;
  position: absolute;
  z-index: 1;
  transition: left .4s cubic-bezier(.47,.13,.15,.89);
}

.slide-2 {
  background-color: green;
}

.slide-3 {
  background-color: red;
}

.slide-4 {
  background-color: purple;
}

.slide-5 {
  background-color: yellow;
}

.nav {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  background-color: rgba(150,150,150,.3);
  width: 20px;
  height: 20px;
  z-index: 2;
  cursor: pointer;
  border-radius: 50%;
  border: none;
  outline: none;
  color: white;
  /* -webkit-user-select: none; */
}

.nav-left {
  left: -25px;
}

.nav-right {
  right: -25px;
}

.carousel-arrow-icon-left {
  margin-left: 5px;
  margin-top: 2px;
}

.carousel-arrow-icon-right {
  margin-left: 7px;
  margin-top: 2px;
}

.rhap_main {
  display: flex;
  flex-direction: column;
  flex: initial !important;
  width:80%;
  margin: auto;
}
.rhap_container{
  flex-direction:row !important;
  width: 100% !important;
  align-items: center;
  box-shadow: none !important;

}
.fixedTop{
  position:fixed;
    top:0; 
    left:0;
    width: 100%; 
    box-shadow: rgb(0 0 0 / 20%) 0px 0px 3px 0px;

}
.stickyTop{
  z-index: 9999;
}

@media(max-width:1400px){
  .cardHead{
    width: 200px;
  }
  
}
@media(max-width:1272px){
  .cardHead{
    width:180px;
  }
  
}
@media(max-width:1024px){
  .cardHead{
    width: 200px;
  }
  
}

.header{
  z-index: 20;
  position: absolute;
  left: 0;
  top: 0;
  height: 62px;
  width: 100%;
  box-shadow: 11px 3px 8px rgb(0 0 0 / 50%);
}
.sidebar{
  position: absolute;
  left: 0;
  top: 66px;
  width: 260px;
  height: calc(100% - 142px);
  overflow-x: hidden;
  overflow-y: auto;
}
.main{
  position: absolute;
  left: 260px;
  width: calc(100% - 262px);
  height: calc(100% - 160px);
  top: 66px;
  overflow-x: hidden;
    overflow-y: auto;
}
.loader {
 
      position: absolute;
       left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%)
      
     }
     .css-i4bv87-MuiSvgIcon-root{
       width: 16px !important;
     }
     
